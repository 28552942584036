<!--
 * @Author: yangliao
 * @Date: 2020-11-25 23:35:37
 * @LastEditTime: 2020-12-28 21:06:29
 * @LastEditors: Please set LastEditors
 * @Description: 过户更名
 * @FilePath: /netHallOfficialAccounts/src/views/Business/Repair/Repair.vue
-->

<template>
  <div>
    <div>
      <business-status
        v-if="statusInfor"
        :statusInfor="statusInfor"
        :busHandlingId="busHandlingId"
      />
      <!-- 报事报修 没有须知页 整合一个页面的改动 -->
      <div class="typeName" v-if="businessState === 'see' && stateBoolean">
        <van-field v-model="businessTypeName" label="报修类型" placeholder="请输入身份证" readonly/>
      </div>
      
      <div class="typeName" v-if="businessState === 'add'">
        <van-field v-if="businessTerms" label="报修类型" center name="radio">
          <template #input>
            <van-radio-group v-model="businessType" direction="horizontal">
              <van-radio
                class="notice-head-radio"
                v-for="item in businessTerms.child"
                :key="item.code"
                :name="item.code"
                @click="businessEvent(item)"
              >
                {{ item.name }}
              </van-radio>
            </van-radio-group>
          </template>
        </van-field>
      </div>
      
      <div v-if="moduleCode === '000301'" class="notice-card">
        <van-cell is-link center @click="cardShow = true">
          <template #title>
            <div v-if="!accountNo" class="custom-title">
              请绑定您要报修的用水账户
            </div>
            <div v-if="accountNo">
              <van-field class="notice-card-cell" label="户号" labelWidth="40" input-align="right">
                <template #input>
                  {{ accountNo }}
                </template>
              </van-field>
              <van-field class="notice-card-cell" label="地址" labelWidth="40" input-align="right">
                <template #input>
                  {{ address.areaInfor }}
                </template>
              </van-field>
            </div>
          </template>
        </van-cell>
      </div>
      
      <!-- <div v-if="moduleCode === '000301'" class="real-infor">
        <van-field readonly label="户号" :input-align="realAlign">
          <template #input>
            <span>{{ accountNo }}</span>
          </template>
        </van-field>
        <van-field readonly label="地址" :input-align="realAlign">
          <template #input>
            <span>{{ address.areaInfor }}</span>
          </template>
        </van-field>
      </div> -->

      <!-- <div class="real-infor">
        <van-field readonly label="新户主" :input-align="realAlign">
          <template #input>
            <span>{{ realName.contacts }}</span>
          </template>
        </van-field>
        <van-field readonly label="手机号" v-model="realName.telephone" :input-align="realAlign" />
        <van-field readonly label="身份证" v-model="realName.idNo" :input-align="realAlign" />
      </div> -->
    </div>
    <div class="vant-parser">
      <parser
        v-if="formConf"
        ref="parser"
        :key="key2"
        :form-conf="formConf"
        @submit="sumbitForm1"
      />
    </div>
    <!-- [nh_00]办理中状态显示 -->
    <div class="footer" v-if="businessState === 'see' && stateBoolean">
      <div class="btns">
        <van-button plain type="info" @click="showCancelReport" style="margin-right: 10px">
          取消办理
        </van-button>
        <van-button plain type="info" @click="updateInfo">修改信息</van-button>
      </div>
    </div>
    <!-- 选择户号 -->
    <van-popup class="card-model" v-model="cardShow" :style="{ width: '100%', height: '100%' }">
      <div class="card-model-group">
        <div class="card-model-group-title">
          <span>已绑定用水账户</span>
        </div>
        <div class="card-model-group-news">
          <van-radio-group v-model="cardRadio">
            <van-radio
              v-for="(item, index) in cardAccount"
              :key="index"
              class="card-model-group-radio"
              :name="item.wuserNo"
              @click="selectCardRadio(item)"
            >
              <div class="cmgr-name"><span>户号：</span>{{ item.wuserNo }}</div>
              <div class="cmgr-name"><span>地址：</span>{{ item.wuserAddress }}</div>
            </van-radio>
          </van-radio-group>
        </div>
        <div class="submit-button">
          <van-button type="info" @click="jumpBind">绑定新用水账户</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
import { Dialog, Toast } from 'vant';
import Parser from '@/components/vant-parser/Parser';
import {
  selectAccount,
  addBusHandling,
  selectWaterUserRel,
  selectBusHandlingById,
  updateBusHandling,
  selectHandUser,
  cancelReport
} from '@/api/business';
import { selectMaxRelease } from '@/api/feedback';
import { termsReckon } from '@/utils/businessRoute';
import BusinessStatus from '../components/BusinessStatus';
export default {
  components: {
    Parser,
    BusinessStatus
  },
  data() {
    return {
      key2: +new Date(),
      formConf: null,
      businessCode: null,
      moduleCode: null,
      custReadingId: null,
      busHandlingId: null,
      /* businessState: add-新增；see-查看；edit-修改 */
      businessState: 'add',
      /* 页面修改，取消 */
      stateBoolean: false,
      formReleaseId: null,
      /* 实名认证信息 */
      // realName: {
      //   contacts: null,
      //   telephone: null,
      //   identityCard: null
      // },
      /* 表卡信息 */
      accountNo: null,
      address: {
        areaInfor: '',
        fullAddressInfor: ''
      },
      /* 业务办理信息 */
      statusInfor: null,
      businessType: '',
      cardShow: false,
      cardAccount: null,
      cardRadio: '',
      businessTypeName: ''
    };
  },
  computed: {
    ...mapState('user', {
      homeInfo: (state) => state.homeInfo,
      pathUrl: (state) => state.pathUrl,
      businessNews: (state) => state.businessNews
    }),
    businessTerms() {
      return termsReckon('0003');
    },
    realAlign() {
      if (this.businessState !== 'see') {
        return 'left';
      }
      return 'right';
    }
  },
  mounted() {
    this.into();
  },
  methods: {
    /* 页面初始化 */
    into() {
      if (this.businessNews && this.businessNews.moduleCode) {
        this.businessCode = this.businessNews.businessCode;
        this.moduleCode = this.businessNews.moduleCode;
        this.custReadingId = this.businessNews.custReadingId;
        this.businessState = this.businessNews.businessState;
        this.busHandlingId =
          this.businessNews.busHandlingId !== undefined ? this.businessNews.busHandlingId : null;
        this.accountNo = this.businessNews.accountNo;
        this.address.areaInfor = this.businessNews.accountAddress;
        this.selectMaxRelease();
        // this.selectWaterUserRel();
        if (this.businessNews.moduleName !== undefined && this.businessNews.moduleName) {
          document.title = this.businessNews.moduleName;
        } else {
          document.title = '报事报修';
        }
      } else {
        this.businessCode = this.businessTerms.code;
        this.selectAccount();
        this.businessEvent(this.businessTerms.child[0]);
        Toast('获取业务类型信息失败，请返回重新选择！');
      }
    },
    /* 业务选择 */
    businessEvent(data) {
      this.checked = false;
      this.businessType = data.code;
      if (data.child !== undefined && data.child.length > 0) {
        this.businessSubclass = '';
        this.moduleCode = '';
      } else {
        this.businessSubclass = '';
        this.moduleCode = data.code;
        this.moduleName = data.name;
        // 获取表单内容
        this.selectMaxRelease();
      }
    },
    /* 获取绑定表卡 */
    async selectAccount() {
      let { status, resultData } = await selectAccount();
      if (status === 'complete' && resultData) {
        this.cardAccount = resultData;
        if (resultData.length === 1) {
          this.cardRadio = resultData[0].wuserNo;
          this.accountNo = resultData[0].wuserNo;
          this.address.areaInfor = resultData[0].wuserAddress;
        }
        // 如果是选择绑定新的户号：
        if (this.pathUrl && this.pathUrl.code === '0003') {
          this.cardRadio = resultData[0].wuserNo;
          this.accountNo = resultData[0].wuserNo;
          this.address.areaInfor = resultData[0].wuserAddress;
        }
      }
    },
    /* 绑定表卡选择 */
    selectCardRadio(data) {
      this.accountNo = data.wuserNo;
      this.address.areaInfor = data.wuserAddress;
      this.cardShow = false;
    },
    /* 新用户绑定挑转 */
    async jumpBind() {
      this.code = '0003' // code: 00 -> 约定成业务里面的
      let data = {
        url: '/Business/Repair',
        code: this.code 
      }
      await store.dispatch('user/savePathUrl', data)
      this.$router.push({ path: '/Bind' });
    },
    /* 页面提交 */
    sumbitForm1(data) {
      let formData = data;
      if (this.moduleCode === '000301') {
        if (this.accountNo) {
          formData = { ...formData, accountNo: this.accountNo, address: this.address };
        } else {
          Toast('请绑定您要办理业务的用水账户')
        }
      }
      if (this.businessState === 'add') {
        let submitArg = {
          businessCode: this.businessCode,
          moduleCode: this.moduleCode,
          custReadingId: this.custReadingId,
          isReading: true,
          formReleaseIds: this.formReleaseId,
          formContent: JSON.stringify(formData),
          verification: {
            wuserNo: this.accountNo,
            moduleCode: this.moduleCode
          }
        };
        this.addBusHandling(submitArg);
      } else {
        let updateArg = {
          busHandlingId: this.busHandlingId,
          formReleaseIds: this.formReleaseId,
          formContent: JSON.stringify(formData),
          verification: {
            wuserNo: this.accountNo,
            moduleCode: this.moduleCode
          }
        };
        this.updateBusHandling(updateArg);
      }
    },
    // 取消
    showCancelReport() {
      Dialog.confirm({
          message: '您确定取消申请吗？',
          confirmButtonText: '取消申请',
          confirmButtonColor: '#3C7EF0',
          cancelButtonText: '点错了',
          cancelButtonColor: '#767E8C',
      })
      .then(() => {
        this.cancelReport();
      })
      .catch(() => {
        console.log('取消申请')
      });
    },
    /* 取消 */
    async cancelReport() {
      let arg = this.busHandlingId;
      const { status, resultData } = await cancelReport(arg);
      if (status === 'complete') {
        Toast('该业务已取消');
        // 回显页面
        this.setBusinessNews('see');
        this.key2 = +new Date();
        this.formConf = { ...this.formConf, readonly: true };
        this.selectBusHandlingById(this.busHandlingId);
      }
    },
    /* 修改信息 */
    async updateInfo(data) {
      this.setBusinessNews('edit');
      this.key2 = +new Date();
      this.formConf = { ...this.formConf, readonly: false };
      this.selectBusHandlingById(this.busHandlingId);
    },
    /* 查询实名信息 */
    // async selectWaterUserRel() {
    //   const { status, resultData } = await selectWaterUserRel();
    //   if (status === 'complete' && resultData) {
    //     this.realName.contacts = resultData.realname;
    //     this.realName.telephone = resultData.phone;
    //     this.realName.idNo = resultData.idNo;
    //   }
    // },
    /* 获取表单样式 */
    async selectMaxRelease() {
      let arg = {
        businessCode: this.businessCode,
        moduleCode: this.moduleCode
      };
      this.formConf = null;
      const { status, resultData } = await selectMaxRelease(arg);
      if (status === 'complete' && resultData) {
        this.formReleaseId = resultData.formReleaseId;
        let data = JSON.parse(resultData.content);
        if (this.businessState === 'see') {
          data = { ...data, readonly: true };
        }
        this.formConf = data;
        if (this.busHandlingId) {
          this.selectBusHandlingById(this.busHandlingId);
        }
        if (this.businessState === 'add') {
          this.selectHandUser();
        }
      }
    },
    /* 数据查询 */
    async selectBusHandlingById(id) {
      const { status, resultData } = await selectBusHandlingById(id);
      if (status === 'complete') {
        this.statusInfor = resultData;
        this.stateBoolean = resultData.stateBoolean;
        let data = JSON.parse(resultData.formContent);
        // 页面回显报事报修类型名
        this.businessType = resultData.moduleCode;
        this.businessTerms && this.businessTerms.child.map((item) => {
          if (item.code === this.businessType) {
            this.businessTypeName = item.name
          }
        })
        // 存储 户号+ 地址
        this.accountNo = data.accountNo;
        this.address = data.address;
        this.$nextTick(() => {
          this.$refs.parser.fillForm(data);
        });
      } else {
        this.statusInfor = null;
      }
    },
    /* 新增提交 */
    async addBusHandling(data) {
      const { status, resultData } = await addBusHandling(data);
      if (status === 'complete') {
        let processingDays = resultData.processingDays;
        this.busHandlingId = resultData.busHandlingId;
        this.handerDialog('提交', processingDays);
      }
    },
    /* 修改更新 */
    async updateBusHandling(arg) {
      const { status, resultData } = await updateBusHandling(arg);
      if (status === 'complete') {
        let processingDays = resultData.processingDays;
        this.busHandlingId = resultData.busHandlingId;
        this.handerDialog('修改', processingDays);
      }
    },
    /* 成功后弹框 */
    handerDialog(dialogName, processingDays) {
      Dialog.confirm({
        title: `您的业务办理已${dialogName}成功！`,
        message:
          processingDays !== undefined
            ? `预计在${processingDays}个工作日内完成，请耐心等待，可至“我的办理”中查看办理进度。`
            : '可至“我的办理”中查看办理进度。',
        confirmButtonText: '查看记录',
        confirmButtonColor: '#3C7EF0',
        cancelButtonText: '返回首页',
        cancelButtonColor: '#767E8C'
      })
        .then(() => {
          // 查看记录
          this.setBusinessNews('see');
          this.key2 = +new Date();
          this.formConf = { ...this.formConf, readonly: true };
          this.selectBusHandlingById(this.busHandlingId);
        })
        .catch(() => {
          // 返回首页
          this.$router.push({ path: '/' });
        });
    },
    /* 业务全局变量修改 */
    setBusinessNews(State) {
      this.businessState = State;
      let item = {
        ...this.businessNews,
        businessState: State,
        busHandlingId: this.busHandlingId,
        accountNo: this.accountNo,
        address: this.address
      };
      store.dispatch('user/setBusinessNews', item);
    },
    async selectHandUser() {
      const {status, resultData} = await selectHandUser("");
      if (status === 'complete') {
        if (resultData.handUserVO && Object.keys(resultData.handUserVO).length > 0) {
          let data = {
            contacts: resultData.handUserVO.wuserName,
            telephone: resultData.handUserVO.phone
          }
          this.$nextTick(() => {
            this.$refs.parser.fillForm(data);
          });
        } else {
          let data = {
            contacts: resultData.waterUserVO.wuserName,
            telephone: resultData.waterUserVO.phone
          }
          this.$nextTick(() => {
            this.$refs.parser.fillForm(data);
          });
        }
      }
    },
  },
  destroyed() {
    // 出去清除路由里面的跳转状态
    if (this.pathUrl && !this.code) {
      this.pathUrl.code = '';
      this.pathUrl.url = '';
    }
  }
};
</script>

<style lang="less" scoped>
.vant-parser {
  margin: 16px 16px 80px 16px;
  overflow: hidden;
}
.typeName {
  margin: 16px;
  /deep/.van-cell {
    border-radius: 8px;
  }
}
.notice-card {
  background: #ffffff;
  margin-left: 16px;
  margin-right: 16px;
  .van-cell::after {
    border: none;
  }
  /deep/.van-cell {
    background-color: transparent;
    border-radius: 8px;
  }
  .custom-title {
    padding: 6px 0;
  }
  .notice-card-cell {
    padding: 6px 0;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
  }
}
.real-infor {
  margin: 16px;
  border-radius: 6px;
  overflow: hidden;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px 15px 0px;
  background: #ffffff;
  width: 100%;
  position: fixed;
  bottom: 0px;

  .btns {
    width: 100%;
    text-align: center;
    margin: 0px 16px 0px 16px;
    /deep/.van-button--normal {
      width: calc(100% / 2 - 10px);
      border-radius: 8px;
    }
  }
}
.card-model {
  background-color: #f6f6f6;
  .card-model-group {
    .card-model-group-title {
      margin: 10px 16px;
      font-family: PingFangSC-Medium;
      color: #1d6fe9;
      font-size: 14px;
    }

    .card-model-group-news {
      margin-bottom: 68px;
      .card-model-group-radio {
        margin: 16px;
        padding: 10px 16px;
        border-radius: 6px;
        overflow: hidden;
        background-color: #ffffff;

        .cmgr-name {
          margin: 10px 6px;
        }
      }
    }

    .submit-button {
      // position: fixed;
      width: 100%;
      height: 100%;
      bottom: 0px;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;

      .van-button__text {
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #ffffff;
        text-align: center;
      }
      .van-button {
        border-radius: 8px;
        width: 90%;
      }
    }
  }
}
</style>
